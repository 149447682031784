<template>
  <section class="depot">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="d-flex align-items-center">
        <h2 class="main-tab__title active">Списание</h2>
      </div>
    </div>

    <div class="d-flex">
      <avesomebar
        class="awesome-table w-100"
        style="max-height: calc(100vh - 250px)"
      >
        <div
          class="accordion accordion-flush w-max"
          id="accordionFlushExample"
          style="min-width: 100%"
        >
          <div
            class="accordion-item w-100"
            v-for="(application, index) in state.applications"
            :key="application.id"
          >
            <h2
              class="accordion-header w-100"
              :id="`product-heading-id-${application.id}`"
            >
              <button
                class="accordion-button collapsed w-100"
                type="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                :data-bs-target="`#product-collapse-id-${application.id}`"
                :aria-controls="`product-collapse-id-${application.id}`"
              >
                <span class="d-inline-block cursor-pointer w-min">
                  {{ index + 1 }}. {{ application.title ?? "-" }}
                </span>
              </button>
            </h2>
            <div
              :id="`product-collapse-id-${application.id}`"
              class="accordion-collapse collapse"
              :aria-labelledby="`product-heading-id-${application.id}`"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <table
                  class="table main-table bordered text-center mb-0"
                  style="font-size: 14px"
                >
                  <thead>
                    <tr>
                      <th scope="col">№</th>
                      <th class="text-start" scope="col">Продукт</th>
                      <th scope="col">Склад</th>
                      <th scope="col">Количество</th>
                      <th scope="col">Единица измерения</th>
                      <th scope="col">Действие</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in application.tasks" :key="item.id">
                      <td :style="{ width: item?.id?.length }">
                        {{ item.id }}
                      </td>
                      <td class="text-start">
                        <router-link
                          class="main-link"
                          :to="{
                            name: 'formation-page',
                            params: { id: parseInt(item.deal_id) },
                          }"
                          >{{ item.product?.title ?? "-" }}</router-link
                        >
                      </td>
                      <td>{{ item.stock?.title ?? "-" }}</td>
                      <td>
                        {{ convertNumber(item.quantity) ?? "-" }}
                      </td>
                      <td>{{ item.product?.measurement?.title ?? "-" }}</td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-gold w-100"
                          :disabled="
                            item.is_written_off || !isRedeemAccountingActive
                          "
                          @click="redeemAccountingItem(item.id)"
                        >
                          Списать
                        </button>
                      </td>
                    </tr>

                    <tr v-show="!application.tasks?.length && !isDataLoading">
                      <td colspan="5">
                        <span class="d-block text-bold my-3"
                          >Ничего не найдено!</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div v-show="!state.applications.length && !isDataLoading">
            <span class="d-block text-bold text-center my-5"
              >Ничего не найдено!</span
            >
          </div>
        </div>
      </avesomebar>
    </div>
    <Pagination
      v-model:currentPage="state.currentPage"
      :totalPages="state.totalPages"
    />
  </section>
</template>

<script>
import { reactive, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import { successAlert, confirmAlert } from "@/helpers/alerts";
import { convertNumber } from "@/helpers/stringUtils";
import useLoadingModal from "@/mixins/useLoadingModal";
import { getAccounting, redeemAccounting } from "@/api/accounting";

import Pagination from "@/components/ui/Pagination.vue";

export default {
  components: {
    Pagination,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      currentPage: 1,
      totalPages: 1,
      applications: [],
      id: route.params.id,
    });
    const { isDataLoading } = useLoadingModal();
    const userPermissions = computed(() => store.getters.getUserPermissions);
    const isRedeemAccountingActive = computed(
      () => userPermissions.value["32"]?.permissions["35"] ?? false
    );

    onMounted(() => {
      getData();
    });

    watch(
      () => state.currentPage,
      () => {
        getData();
      }
    );

    async function getData() {
      try {
        isDataLoading.value = true;
        const res = await getAccounting(state.currentPage);
        state.applications = res.data;
        state.totalPages = res.meta?.last_page;
      } finally {
        isDataLoading.value = false;
      }
    }

    const redeemAccountingItem = (id) => {
      confirmAlert("Подтвердить действие").then(async (result) => {
        if (result.isConfirmed) {
          try {
            isDataLoading.value = true;
            await redeemAccounting(id);
            await getData();
            successAlert("Успешно списано!");
          } catch {
            isDataLoading.value = false;
          }
        }
      });
    };

    return {
      state,
      isDataLoading,
      getData,
      convertNumber,
      isRedeemAccountingActive,
      redeemAccountingItem,
    };
  },
};
</script>